.sign-in {
  min-height: 100vh;

  .logo-sign-in { height: 40px; }

  .card {
    min-width: 350px;
    width: 90%;
    max-width: 400px;
  }
}